//Lib
import React from "react";

//Components
import BlogCard from "./blogCard";

//Styles
import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";

//Content
import HeroImage from "../../assets/images/heroImage.png";

const Blog = ({ posts }) => {
  return (
    <section className="page-header" style={{ marginBottom: "40px" }}>
      <div className="page-header-bg"></div>

      <div className="page-header-shape-1"></div>
      <div className="page-header-shape-2"></div>
      <div className="page-header-shape-3"></div>

      <div className="container text-center">
        <div className="page-header__inner">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className=" ">
                <img src={HeroImage} alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <h2>Blog</h2>
              <hr />
              <p>
                The latest articles and updates from Hyperdex.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-5 text-center">
          <div className="mt-5">
            <h2 className="text-center mt-5 fixedincome">
              BLOG POSTS & ARTICLES
            </h2>
          </div>

          <div className="row mt-5 custom-row">
            <div className="d-flex justify-content-between">
              <h4 className="text-white">Featured Articles -</h4>
              <h4 className="text-white">See All Articles &#62;</h4>
            </div>
            {posts.map((item) => (
              <div className="col-md-3 mt-5">
                <BlogCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
