import * as React from "react";
import { graphql } from "gatsby";
import Blog from "../../modules/blog/index";

const BlogPage = ({ data }) => {
  const posts = data.allSanityPost.nodes;

  const getReadingTime = (body) => {
    let textArray = [];
    body.forEach(element => {
      textArray = textArray.concat(element.children);
    });
    let readingTimeInMinutes = textArray.reduce((accumulator, element) => {
      return accumulator + element.text.length;
    }, 0);
    readingTimeInMinutes = Math.ceil(readingTimeInMinutes / 5 / 180);
    return readingTimeInMinutes;
  }
  
  posts.forEach((element) => {
    element.readingTimeInMinutes = getReadingTime(element.body);
  });

  return <Blog posts={posts} />;
};

export const query = graphql`
  query {
    allSanityPost(limit: 4, sort: { fields: _createdAt, order: DESC }) {
      nodes {
        _id
        title
        mainImage {
          asset {
            url
          }
        }
        publishedAt
        authors {
          author {
            name
            image {
              asset {
                url
              }
            }
          }
        }
        slug {
          current
        }
        body {
          children {
            text
          }
        }
      }
    }
  }
`;

export default BlogPage;
